import React, { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import DropDown from "./NavDropDown";
import DropDownProfile from "./NavDropDownProfile";
import { AuthContext } from "../../context/auth-context";

import "./NavLinks.css";

const NavLinks = (props) => {
  const auth = useContext(AuthContext);
  const [dropdown, setDropdown] = useState(false);
  const [dropdownProfile, setDropdownProfile] = useState(false);

  const onMouseClick = () => {
    setDropdown(!dropdown);
  };

  const onMouseClickProfile = () => {
    setDropdownProfile(!dropdownProfile);
  };

  // console.log("dropdown", dropdown);

  return (
    <ul className="nav-links">
      <li>
        <NavLink to="/about" exact onClick={props.toggleDrawerClose}>
          ABOUT
        </NavLink>
      </li>
      {auth.isLoggedIn && (
        <li>
          <NavLink to="/allUsers" exact onClick={props.toggleDrawerClose}>
            PUBLIC USERS
          </NavLink>
        </li>
      )}
      {auth.isLoggedIn && (
        <li>
          <NavLink to="/items/search" onClick={props.toggleDrawerClose}>
            SEARCH
          </NavLink>
        </li>
      )}
      {auth.isLoggedIn && (
        <li className="nav-items-dropdown">
          <button onClick={onMouseClick}>
            Inventory <i className="fas fa-caret-down" />
            {dropdown && (
              <DropDown toggleDrawerClose={props.toggleDrawerClose} />
            )}
          </button>
        </li>
      )}
      {auth.isLoggedIn && (
        <li className="nav-items-dropdown">
          <button onClick={onMouseClickProfile}>
            Profile <i className="fas fa-caret-down" />
            {dropdownProfile && (
              <DropDownProfile toggleDrawerClose={props.toggleDrawerClose} />
            )}
          </button>
        </li>
      )}
      {!auth.isLoggedIn && (
        <li>
          <NavLink to="/auth" onClick={props.toggleDrawerClose}>
            Log In | Sign up
          </NavLink>
        </li>
      )}
    </ul>
  );
};

export default NavLinks;
